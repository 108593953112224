import axios from "axios";

const URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_BASEURL
    : process.env.REACT_APP_BASEURL_DEVELOPMENT;

const apiLidar = axios.create({
  baseURL: URL
    //'localhost:3005'
    // process.env.REACT_APP_BASEURL_DEV,
    // process.env.REACT_APP_BASEURL_DEVELOPMENT,
    //process.env.REACT_APP_BASEURL,
});

export default apiLidar;
