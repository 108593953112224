// Import React's Components
import React from "react";

// Import Ant Design Components
import { Layout, Space, Spin } from "antd";
import { Content } from "antd/es/layout/layout";

import "../../../App.css";
import { Loading3QuartersOutlined } from "@ant-design/icons";

// CODE
export default function LoadingComponents() {
  return (
    <div className="loading-container">
      <Layout style={{ minHeight: "90vh", marginTop: 65 }}>
        <Content
          style={{
            paddingTop: 250,
            margin: 10,
            textAlign: "center",
          }}
        >
          <Space>
            <Spin
              size="large"
              indicator={
                <Loading3QuartersOutlined spin style={{ fontSize: 150 }} />
              }
              // tip="Please, Wait! Data is being Load..."
              style={{ fontSize: 30 }}
            />
          </Space>
        </Content>
      </Layout>
    </div>
  );
}
