//IMPORT REACT COMPONENTS
import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as AppContext } from "../../API/Context/AppContext";

// NOTIFICATION
import { showFailedFetch } from "../../Components/Notification/Notification";

//IMPORT ANTD COMPONENTS
import { Content } from "antd/es/layout/layout";
import { Card, Col, Layout, Row, Space, Switch, Typography, Modal } from "antd";

//IMPORT COMPONENTS
import LoadingComponents from "../../Components/Reusable/Loading/Loading";
import { BsWhatsapp, BsSave, BsImage } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { LiaTelegramPlane } from "react-icons/lia";
import {
  CheckOutlined,
  CloseOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";

const colStyle = {
  backgroundColor: "#d6e4ff",
  padding: 5,
};

export default function StatusPage() {
  const timer = useRef(null);

  const [load, setLoad] = useState(true);
  const [statusMessage, setStatusMessage] = useState();
  const [updateStatusMessage, setUpdateStatusMessage] = useState();

  const { getStatusMessage, postStatusMessage } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content");
  const [modalTitle, setModalTitle] = useState("Title");
  const [modal, contextHolder] = Modal.useModal();

  const order = (unordered) =>
    Object.keys(unordered)
      .sort()
      .reduce((obj, key) => {
        obj[key] = unordered[key];
        return obj;
      }, {});

  const sortObjectDeeply = (object) => {
    for (let [key, value] of Object.entries(object)) {
      if (typeof value === "object") {
        object[key] = sortObjectDeeply(value);
      }
    }
    return order(object);
  };

  // FETCH DATA
  const fetchData = () => {
    getStatusMessage({
      mainData: "status-message",
      onAwait: () => "on Await",
      onSuccess: (response) => {
        const resp_data = response.data?.length > 0 ? response.data : [];
        console.log("**fetch status-message => ", resp_data);

        const array_sort = resp_data.sort((a, b) => {
          return a.id - b.id;
        });

        const resp_sort = array_sort.map((item) => {
          return sortObjectDeeply(item);
        });

        setStatusMessage(resp_sort);
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  // POST DATA
  const postData = () => {
    postStatusMessage({
      mainData: "status-message",
      mainBody: updateStatusMessage,
      onAwait: () => "on Await",
      onSuccess: (response) => {
        console.log("**post status-message => ", response.data);
        if (response.status == 201) {
          clearTimeout(timer.current);
          setOpen(false);
          fetchData();
        }
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  useEffect(() => {
    fetchData();
    const interval = setTimeout(() => {
      setLoad(false);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      postData();
      setConfirmLoading(false);
    }, 500);

    timer.current = setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 5000);
  };

  const handleCancel = () => {
    setOpen(false);
    setConfirmLoading(false);
  };

  const countDown = (param) => {
    const instance = modal.success({
      bodyStyle: ({marginTop: 10, marginBottom: 0}),
      title: param.title,
      content: param.content,
      centered: true,
      icon: (
        <CloseCircleTwoTone
          style={{ marginLeft: 32, marginTop: 5, fontSize: "18px" }}
          twoToneColor={"#eb2f96"}
        />
      ),
    });
    setTimeout(() => {
      instance.destroy();
    }, 5000);
  };

  const onCheckedChange = (param) => {
    const { id, location, status, config } = param;
    if (status == 1) {
      setModalText(`Are you sure to change the ${config.service_name} feature?`);
      setModalTitle(`${id} - ${location}`);
      setUpdateStatusMessage({
        device_id: id,
        service: config.service,
        status: !config.status,
      });
      showModal();
    } else {
      countDown({
        content: `The device ${id} is offline, the change feature is not available.`,
        title: `${id} - ${location}`,
      });
    }
  };

  if (load == true) {
    return <LoadingComponents />;
  }

  return (
    <>
      <Layout style={{ minHeight: "85vh", marginTop: 60 }}>
        <Content style={{ padding: 10, margin: 10 }}>
          <Card>
            <Typography.Title style={{ textAlign: "center", paddingTop: 10 }}>
              Settings 
            </Typography.Title>
          </Card>
          {statusMessage
            ? statusMessage.map((items, indexs) => {
                return (
                  <Card
                    style={{ marginTop: 10, padding: 20 }}
                    key={`${indexs}_${items.device_id}`}
                  >
                    <Space
                      align="center"
                      direction="horizontal"
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        marginBottom: 20,
                      }}
                    >
                      {items.status == 1 ? (
                        <CheckCircleTwoTone
                          style={{ marginTop: 5, fontSize: "24px" }}
                          twoToneColor={"#52c41a"}
                        />
                      ) : (
                        <CloseCircleTwoTone
                          style={{ marginTop: 5, fontSize: "24px" }}
                          twoToneColor={"#eb2f96"}
                        />
                      )}
                      <Typography.Title
                        style={{
                          fontSize: 24,
                          textAlign: "center",
                          paddingTop: 10,
                        }}
                      >
                        Current Features {items.device_id} - {items.location}
                      </Typography.Title>
                    </Space>

                    <Row gutter={[30, 30]}>
                      {items?.config
                        ? Object.values(items.config).map((item, index) => {
                            return (
                              <Col
                                span={8}
                                key={`${indexs}_${items.device_id}_${index}_${items.id}`}
                              >
                                <Card bordered={false} style={colStyle}>
                                  <Space direction="vertical" style={{padding: 5}}>
                                    <Space align="center" style={{fontSize: 15, fontWeight: 600}}>
                                      {item.service == "notif_email" ? (
                                        <AiOutlineMail
                                          style={{ marginTop: 6, fontSize: 16 }}
                                        />
                                      ) : null}
                                      {item.service == "notif_telegram" ? (
                                        <LiaTelegramPlane
                                          style={{ marginTop: 6, fontSize: 16 }}
                                        />
                                      ) : null}
                                      {item.service == "notif_whatsapp" ? (
                                        <BsWhatsapp style={{ marginTop: 6 }} />
                                      ) : null}
                                      {item.service == "save_data" ? (
                                        <BsSave style={{ marginTop: 5 }} />
                                      ) : null}
                                      {item.service == "save_image" ? (
                                        <BsImage style={{ marginTop: 5 }} />
                                      ) : null}
                                      {item.service_name || "-"}
                                    </Space>
                                    <Row>
                                      Status :{" "}
                                      {item.status
                                        ? "Enabled"
                                        : "Disabled" || "-"}
                                    </Row>
                                    <Row>Receiver : {item.receiver || "-"}</Row>
                                    <Switch
                                      checkedChildren={<CheckOutlined />}
                                      unCheckedChildren={<CloseOutlined />}
                                      checked={item.status}
                                      onChange={() =>
                                        onCheckedChange({
                                          id: items.device_id,
                                          location: items.location,
                                          status: items.status,
                                          config: item,
                                        })
                                      }
                                    />
                                  </Space>
                                </Card>
                              </Col>
                            );
                          })
                        : null}
                    </Row>
                  </Card>
                );
              })
            : null}
          <Modal
            centered
            title={
              <Space direction="horizontal">
                <CheckCircleTwoTone
                  style={{ marginTop: 5, fontSize: "18px" }}
                  twoToneColor={"#52c41a"}
                />
                <Typography.Title
                  style={{
                    fontSize: 16,
                    textAlign: "center",
                    paddingTop: 10,
                  }}
                >
                  {modalTitle}
                </Typography.Title>
              </Space>
            }
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <p>{modalText}</p>
          </Modal>
          {contextHolder}
        </Content>
      </Layout>
    </>
  );
}
