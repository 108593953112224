// Import React's Components
import React, { useContext, useEffect, useState } from "react";

// Import Ant Design Components
import {
  Card,
  Col,
  Form,
  Image,
  Layout,
  List,
  Row,
  Space,
  Typography,
} from "antd";
import moment from "moment";

//CONTEXT
import { Context as AppContext } from "../../../API/Context/AppContext";

// WEBSOCKET
//import { io } from "socket.io-client";

//NOTIFICATION
// import { showFailedFetch } from "../../Notification/Notification";
// import { Content } from "antd/es/layout/layout";
// import HistoryTable from "../Table/HistoryTable";
// import Last5Table from "../Table/Last5";
// import StatusTable from "../Table/StatusTable";

// FORM ITEM LAYOUT
const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: "100%",
  },
};

let textStyle = { fontSize: 22 };

// CODE
export default function MonitorCard(props) {
  const {newEvent} = props;

  // CONTEXT
  const { getData } = useContext(AppContext);

  // STATE MANAGEMENT
  // Data
  const [dataMonitor, setDataMonitor] = useState([]);
  const [data, setData] = useState();
  const [date, setDate] = useState(null);

  //const websocketURL = process.env.REACT_APP_WEBSOCKET_BASEURL;
  //console.log(websocketURL);

  // WEB SOCKET
  //const socket = io(websocketURL);

  // Use Form
  const [form] = Form.useForm();

  // FETCH DATA
  /*
  const fetchData = () => {
    getData({
      mainData: "last-data",
      onAwait: () => "on Await",
      onSuccess: (response) => {
        console.log("FETCH event => ", response.data);
        setDataMonitor(response?.data?.length > 0 ? response.data[0] : []);
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  */

  // SOCKET IO
  /*
  useEffect(() => {
    // Connected
    socket.on("connnect", (response) => {
      console.log("**connected to server", response);
    });

    socket.on("disconnect", () => {
      console.log("**disconnected from server");
      socket.disconnect();
    });

    socket.on("NEW DATA", (data) => {
      console.log("**Data available");
      console.log("PONG DATA: ", data);
      // setData(data.payload);
      fetchData();
    });

    return () => {
      socket.off("connect");
      socket.off("connnection");
      socket.off("error");
      socket.off("pong");
    };
  }, []);
  */

  useEffect(() => {
      setDataMonitor(newEvent || {});
  }, [newEvent]);

  // USE EFFECT
  useEffect(() => {
    let format = moment(dataMonitor?.timestamp, "YYYYMMDDHHmmss");
    let datenew = format.toDate().toString();
    let datereal = moment(datenew).format("DD-MM-YYYY, HH:mm:ss");

    // console.log(format);

    setDate(datereal);
  }, [dataMonitor]);

  const famDom = [
    "momonospace",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Arial",
    "Roboto",
  ];

  const changeOnMe = () => {
    textStyle = {
      fontSize: 22,
      fontFamily: famDom[Math.floor(Math.random() * famDom.length)],
    };
  };

  // console.log("DATA MONITOR ==> ", dataMonitor?.timestamp);
  // console.log("DATA TIMESTAMP ==> ", date);

  return (
    <>
      <Row
        justify={"space-between"}
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        style={{ marginTop: 20, height: "100%" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Card
            className="description-monitor"
            title="Conclusion Vehicle Type"
            onClick={() => changeOnMe()}
            style={{
              fontSize: 26,
              fontWeight: "bold",
              textAlign: "center",
              verticalAlign: "middle",
              justifyContent: "center",
              padding: "5px",
              height: "100%",
            }}
          >
            <Space style={{ width: "100%", justifyContent: "center" }}>
              <Form
                {...formItemLayout}
                size="small"
                style={{ width: "100%", minWidth: "500px" }}
              >
                <Form.Item
                  className="label-monitor"
                  label="Timestamp"
                  style={textStyle}
                  width="100%"
                >
                  {date ? date : "No Data"}
                </Form.Item>

                <Form.Item
                  className="label-monitor"
                  label="Length"
                  style={textStyle}
                >
                  {dataMonitor?.length >= 0
                    ? dataMonitor.length + " mm"
                    : "No Data"}
                </Form.Item>

                <Form.Item
                  className="label-monitor"
                  label="Width"
                  style={textStyle}
                >
                  {dataMonitor?.width ? dataMonitor.width + " mm" : "No Data"}
                </Form.Item>
                <Form.Item
                  className="label-monitor"
                  label="Height"
                  style={textStyle}
                >
                  {dataMonitor?.height ? dataMonitor.height + " mm" : "No Data"}
                </Form.Item>
                {/* <Form.Item
                  className="label-monitor"
                  label="Axle"
                  style={textStyle}
                >
                  {dataMonitor?.length > 0 ? dataMonitor[0]?.axle : "No Data"}
                </Form.Item> */}
                <Form.Item
                  className="label-monitor"
                  label="Speed"
                  style={textStyle}
                >
                  {dataMonitor?.speed >= 0
                    ? dataMonitor.speed + " km/h"
                    : "No Data"}
                </Form.Item>
              </Form>
            </Space>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          {/* <Row> */}
          <Card
            className="picture-monitor"
            title="Images Capture"
            style={{
              fontSize: 26,
              fontWeight: "bold",
              textAlign: "center",
              verticalAlign: "middle",
              padding: "5px",
              height: "100%",
            }}
          >
            <Space style={{ marginTop: 10 }}>
              <Image
                className="live-img"
                src={
                  dataMonitor?.image_capture
                    ? dataMonitor.image_capture
                    : "error"
                }
                width="100%"
              />
            </Space>
          </Card>

          {/* <Card
              className="picture-monitor"
              title="Images PCL"
              style={{
                fontSize: 26,
                fontWeight: "bold",
                textAlign: "center",
                verticalAlign: "middle",
                padding: "5px",
                height: "100%",
                marginTop: "10px",
              }}
            >
              <Space style={{ marginTop: 10 }}>
                <Image
                  className="live-img-pcl"
                  src={
                    dataMonitor?.length > 0 ? dataMonitor[0].image_pcl : "error"
                  }
                  width="100%"
                />
              </Space>
            </Card> */}
          {/* </Row> */}
        </Col>

        <Row
          justify={"space-between"}
          // gutter={[30, 30]}
          style={{ width: "100%" }}
        ></Row>
      </Row>
    </>
  );
}
