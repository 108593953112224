// IMPORT REACTS
import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardPage from "../../Pages/Dashboard/Dashboard";
import Login from "../../Pages/Login/Login";
import HistoryPage from "../../Pages/History/History";
import ChartPage from "../../Pages/Analytics/Analytics";
import StatusPage from "../../Pages/Status/Status";

// CODE
export default function Navigation() {
  // GET ACCESS TOKEN
  const token = sessionStorage.getItem("auth-token");
  const admin = sessionStorage.getItem("admin") == "true" ? true : false;

  return (
    <>
      {token ? (
        admin == true ? (
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/analytics" element={<ChartPage />} />
            <Route path="/status" element={<StatusPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/analytics" element={<ChartPage />} />
            <Route path="/status" element={<StatusPage />} />
          </Routes>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Login />} />
        </Routes>
      )}
    </>
  );
}
