// IMPORT REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// CONTEXT
import { Context as AppContext } from "../../../API/Context/AppContext";

// WEBSOCKET
//import { io } from "socket.io-client";

// ANTD COMPONENTS
import {
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Image,
  Table,
  Statistic,
  Typography,
} from "antd";
import {} from "@ant-design/icons";

// NOTIFICATION
import { showFailedFetch } from "../../Notification/Notification";

// CODE
export default function Last5Table(props) {
  const {newEvent} = props;

  // COLUMN FOR TABLE
  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      sorter: (a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix(),
      render: (timestamp, record) => {
        // let dte = "20230626190015398";

        // var date = new Date(timestamp);
        // var format = moment(date).format("DD-MM-YYYY, HH:mm:ss");

        // console.log(date);
        // console.log(format);
        let format = moment(timestamp, "YYYYMMDDHHmmss");
        let datenew = format.toDate().toString();
        let datereal = moment(datenew).format("DD-MM-YYYY, HH:mm:ss");

        // console.log(datenew);
        // return `${moment(format).format("YYYY-MM-DD HH:mm:ss")}`;
        return `${datereal}`;
      },
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Lane",
      dataIndex: "lane",
      key: "lane",
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "Width",
      dataIndex: "width",
      key: "width",
    },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
    },
    {
      title: "Speed",
      dataIndex: "speed",
      key: "speed",
      sorter: (a, b) => a.speed - b.speed,
      render: (speed, record) => {
        return speed == 0 ? "-" : speed + " km/h";
      },
    },
  ];

  // GET DATE NOW
  const dates = new Date(Date.now());
  // CONTEXT
  const { getLast5 } = useContext(AppContext);
  // DATA
  const [data, setData] = useState([]);
  const [dataStatus, setDataStatus] = useState([]);
  const [savedData, setSavedData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // DATE
  const [date, setDate] = useState(moment(dates).format("YYYY-MM-DD"));
  
  //const websocketURL = process.env.REACT_APP_WEBSOCKET_BASEURL;
  //console.log(websocketURL);

  // WEB SOCKET
  //const socket = io(websocketURL);

  // GET DATA
  const fetchData = () => {
    getLast5({
      mainData: "5-data",
      onAwait: () => "on Await",
      onSuccess: (response) => {
        console.log("FETCH last5 => ", response);
        setData(response?.data);
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, [newEvent]);

  // SOCKET IO
  /*
  useEffect(() => {
    // Connected
    socket.on("connnect", (response) => {
      console.log("connected to server", response);
    });

    // socket.on("connnection", (sockets) => {
    //   console.log(sockets.id);
    // });

    socket.on("NEW DATA", (data) => {
      console.log("Data available");
      //console.log("PONG DATA: ", data);
      // setData(data.payload);
      fetchData();
    });

    return () => {
      socket.off("connect");
      socket.off("connnection");
      socket.off("error");
      socket.off("pong");
    };
  }, []);
  */

  // SHOW MODAL
  const showModal = (record) => {
    setIsModalOpen(true);
    setSavedData(record);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log(data);

  return (
    <>
      <Card style={{ padding: "10px 10px 10px 10px" }}>
        <Typography.Title style={{ textAlign: "center", paddingTop: 10 }}>
          Last 5 Vehicle
        </Typography.Title>
        <Table
          columns={columns}
          dataSource={data}
          bordered={true}
          rowKey={(record) => (record?.id ? record?.id : record.timestamp)}
        />
      </Card>

      <Modal
        title="View Data"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={2000}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Card style={{ fontSize: 14 }}>
              <>
                <Divider orientation="left">Detailed View</Divider>
                Timestamp:{" "}
                {savedData
                  ? moment(savedData.timestamp).format("YYYY-MM-DD HH:mm:ss")
                  : "kosong"}
                <br />
                UID: {savedData ? savedData.uid : "kosong"}
                <br />
                {/* Counter: {savedData ? savedData?.counter : "kosong"}
                <br /> */}
                Lane: {savedData ? savedData.lane : "kosong"}
                <br />
                Length: {savedData ? savedData.length : "kosong"}
                <br />
                Width: {savedData ? savedData.width : "kosong"}
                <br />
                Height: {savedData ? savedData.height : "kosong"}
                <br />
                {/* Axle: {savedData ? savedData.axle : "kosong"}
                <br /> */}
                Speed:{" "}
                {savedData
                  ? savedData?.speed == 0
                    ? "-"
                    : savedData?.speed + " km/h"
                  : "kosong"}
                <br />
              </>
            </Card>
          </Col>

          {/* <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Space>
              <Card>
                <Image
                  src={savedData ? savedData.detection_picture : "kosong"}
                  width={"100%"}
                />
              </Card>
            </Space>
          </Col> */}
        </Row>
      </Modal>
    </>
  );
}
