// IMPORT REACTS COMPONENTS
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

// IMPORT "antd/dist/antd.css"
// import "antd/dist/antd.min.css";
import "antd/dist/reset.css";

// IMPORT PROVIDE
import { Provider as AppProvider } from "./API/Context/AppContext";

// IMPORT COMPONENTS
import LayoutPage from "./Components/Layout/Layout";

// IMPORT MOMENT
import moment from "moment";

// CODE
function App() {
  // DOCUMENT TITLE
  useEffect(() => {
    document.tittle = "LIDAR APP";
  }, []);

  moment.suppressDeprecationWarnings = true;

  return (
    <div className="app">
      <AppProvider>
        <BrowserRouter>
          <LayoutPage />
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

export default App;
