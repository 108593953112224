import React, { useContext, useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { Context as AppContext } from "../../../API/Context/AppContext";
import { showFailedFetch } from "../../Notification/Notification";
import {
  BarChart,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
// import { Tooltip } from "antd";
// import { Bar } from "@ant-design/plots/es";

export default function ChartColumn() {
  const [data, setData] = useState([]);
  const { getDailyChart } = useContext(AppContext);

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // GET DATA
  const fetchData = () => {
    getDailyChart({
      mainData: "daily",
      onAwait: () => "On Await",
      onSuccess: (response) => {
        console.log("FETCH ==> ", response);
        setData(response?.data?.jam);
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  console.log("Charts data --> ", data);

  return (
    <>
      <ResponsiveContainer width={"100%"} height={500} className={"bar-chart"}>
        <BarChart data={data ? data : []}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="jam" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="small" fill="#8884d8" />
          <Bar dataKey="medium" fill="#82ca9d" />
          <Bar dataKey="large" fill="#5F9EA0" />
          <Bar dataKey="long" fill="#556B2F" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
  // const config = {
  //   data,
  //   xField: "jam",
  //   yField: "value",
  //   seriesField: "type",
  //   isGroup: true,
  //   columnStyle: {
  //     radius: [20, 20, 0, 0],
  //   },
  // };

  // return <Column {...config} />;
}
