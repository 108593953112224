// Import Ant Design's Notification Components
import { notification } from "antd";
import { AlertOutlined, WarningOutlined } from "@ant-design/icons";

// LOGIN NOTIFICATION
// LOGIN FAILED NOTIFICATION
const showLoginFailed = (error) => {
  notification.error({
    className: "failed-login",
    message: <div style={{ color: "#f5222d" }}>LOGIN FAILED!</div>,
    description: <>Please, Check Your Email and Password Again!</>,
    icon: <WarningOutlined />,
    placement: "top",
    style: {
      fontWeight: "bold",
      backgroundColor: "#fff1f0",
      color: "#f5222d",
    },
  });
};

// LOGIN SUCCESS NOTIFICATION
const showLoginSuccess = (response) => {
  notification.success({
    className: "success-login",
    message: "LOGIN SUCCESS!",
    description: "Now Logging In!",
    placement: "top",
    style: {
      fontWeight: "bold",
      backgroundColor: "#f6ffed",
    },
  });
};

// FETCH DATA FAILED NOTIFICATION
const showFailedFetch = (error) => {
  notification.error({
    className: "error-fetch",
    message: <div style={{ color: "#ffffff" }}>GET DATA FAILED!</div>,
    description: <>Please, Try Again Later!</>,
    icon: <AlertOutlined />,
    placement: "topRight",
    style: {
      color: "#ffffff",
      fontWeight: "bold",
      backgroundColor: "#f5222d",
    },
  });
};

export { showLoginSuccess, showLoginFailed, showFailedFetch };
