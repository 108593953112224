import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// TURN OFF CONSOLE LOG
if (process.env.REACT_APP_NODE_ENV === "production") {
  // if (process.env.REACT_APP_NODE_ENV == "development") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
reportWebVitals();
