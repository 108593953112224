import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Context as AppContext } from "../../API/Context/AppContext";
import { Card, Layout, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import LoadingComponents from "../../Components/Reusable/Loading/Loading";
import ChartColumn from "../../Components/Reusable/Chart/ChartColumn";
import InfographyColumn from "../../Components/Reusable/Infography/Infography";
import Datetime from "../../Components/Reusable/Date/Date";

export default function ChartPage() {
  const [load, setLoad] = useState(true);
  const dateTime = new Date();

  useEffect(() => {
    const interval = setTimeout(() => {
      setLoad(false);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (load == true) {
    return <LoadingComponents />;
  }

  return (
    <>
      <Layout style={{ minHeight: "85vh", marginTop: 60 }}>
        <Content style={{ padding: 10, margin: 10 }}>
          <Card>
            <Typography.Title style={{ textAlign: "center", paddingTop: 10 }}>
              Analytics Daily / {moment(dateTime).format("D-MM-YYYY")}
            </Typography.Title>
          </Card>

          <Card style={{ marginTop: 10 }}>
            <Typography.Title
              style={{ fontSize: 24, textAlign: "center", paddingTop: 10 }}
            >
              Infographics
            </Typography.Title>

            {/* <Content style={{ padding: 10, margin: 10 }}> */}
            <InfographyColumn />
            {/* </Content> */}
          </Card>

          <Card style={{ marginTop: 10 }}>
            <Typography.Title
              style={{ fontSize: 24, textAlign: "center", paddingTop: 10 }}
            >
              Chart
            </Typography.Title>

            {/* <Content style={{ padding: 10, margin: 10 }}> */}
            <ChartColumn />
            {/* </Content> */}
          </Card>
        </Content>
      </Layout>
    </>
  );
}
