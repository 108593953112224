// IMPORT REACTS COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// CONTEXT
import { Context as AppContext } from "../../API/Context/AppContext";

// ANTD COMPONENTS
import { Card, Layout, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import LoadingComponents from "../../Components/Reusable/Loading/Loading";
import HistoryTable from "../../Components/Reusable/Table/HistoryTable";
import StatusTable from "../../Components/Reusable/Table/StatusTable";

// CODE
export default function HistoryPage() {
  // STATE MANAGEMENT
  // Loading
  const [load, setLoad] = useState(true);

  // USE EFFECT
  useEffect(() => {
    // fetchData();

    const interval = setTimeout(() => {
      setLoad(false);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  //   Loading Screen
  if (load == true) {
    return <LoadingComponents />;
  }

  return (
    <>
      <Layout
        style={{
          minHeight: "85vh",
          marginTop: 60,
          // backgroundImage: "url(palimanan-2.jpg)",
          // opacity: 0.9,
        }}
      >
        <Content style={{ padding: 10, margin: 10 }}>
          <Card bordered hoverable>
            <Typography.Title style={{ textAlign: "center", paddingTop: 10 }}>
              Last 100 Vehicle
            </Typography.Title>
          </Card>
        </Content>

        <Content style={{ padding: 10, margin: 10 }}>
          <HistoryTable is_history={false} />
        </Content>

        {/* <Content style={{ padding: 10, margin: 10 }}>
          <StatusTable />
        </Content> */}
      </Layout>
    </>
  );
}
