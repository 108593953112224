// IMPORT REACTS
import React, { useContext, useEffect, useState, useRef } from "react";

// CONTEXT
import { Context as AppContext } from "../../API/Context/AppContext";

// ANTD COMPONENTS
import { Card, Col, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

// PAGES COMPONENTS
import MonitorCard from "../../Components/Reusable/Monitor/Monitor";
// import ConclusionTable from "../../Components/Reusable/Table/ConclusionTable";
// import CardComponents from "../../Components/Reusable/Card/Card";
import LoadingComponents from "../../Components/Reusable/Loading/Loading";
import StatusTable from "../../Components/Reusable/Table/StatusTable";
import Last5Table from "../../Components/Reusable/Table/Last5";
// import ListView from "../../Components/Reusable/List/ListView";

// NOTIFICATIONS
// import Chartbar from "../../Components";
import { showFailedFetch } from "../../Components/Notification/Notification";

// SOCKET IO
import { socket } from "../../socket";

// CODE
export default function DashboardPage() {
  // CONTEXT
  const { getData } = useContext(AppContext);
  const timer = useRef(null);

  // STATE MANAGEMENT
  // LOADING
  const [load, setLoad] = useState(true);
  // const [data, setData] = useState();

  const [incomingEvent, setIncomingEvent] = useState();
  const [socketConnection, setSocketConnection] = useState();

  // FETCH DATA
  const fetchData = () => {
    getData({
      mainData: "last-data",
      onAwait: () => "on Await",
      onSuccess: (response) => {
        console.log("**fetch last-data => ", response.data);
        setIncomingEvent(response?.data?.length > 0 ? response.data[0] : null);
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    const interval = setTimeout(() => {
      fetchData();
      setLoad(false);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // SOCKET IO without disconnect on unmounted, note: autoConnect: true or default without this path
  /**
  useEffect(() => {
    const onConnect = (response) => {
      console.log("connected to server", response);
    }

    const onDisconnect = () => {
      console.log("disconnected from server");
    }

    const onIncomingEvent = (value) => {
      console.log("event data: ", value);
      setIncomingEvent(previous => [...previous, value]);
      fetchData();
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('NEW DATA', onIncomingEvent);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('NEW DATA', onIncomingEvent);
    };
  }, []);
  */

  const tryReconnect = () => {
    timer.current = setTimeout(() => {
      socket.io.open((err) => {
        if (err) {
          console.log(`socket.io.open((err) => { ${err.message} })`);
        } else clearTimeout(timer.current);
      });
    }, 10000);
  };

  // SOCKET IO with disconnect on unmounted
  useEffect(() => {
    // no-op if the socket is already connected
    socket.connect();

    return () => {
      socket.disconnect();
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    setSocketConnection(socket.connected);

    return () => {
      setSocketConnection();
    };
  }, [socket.connected]);

  let retry = 0;
  useEffect(() => {
    const onConnect = () => {
      console.log("socket connect with id:", socket.id);
      retry = 0;
    };

    const onDisconnect = () => {
      console.log("socket disconnect");
      retry = 0;
    };

    const onConnectFailed = () => {
      if (++retry < 4) tryReconnect();
      console.log("socket connect failed");
    };

    const onConnectError = () => {
      if (++retry < 4) tryReconnect();
      console.log("socket connect error", retry);
    };

    const onError = () => {
      if (++retry < 4) tryReconnect();
      console.log("socket error");
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_failed", onConnectFailed);
    socket.on("connect_error", onConnectError);
    socket.on("error", onError);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_failed", onConnectFailed);
      socket.off("connect_error", onConnectError);
      socket.off("error", onError);
    };
  }, [socketConnection]);

  useEffect(() => {
    const onIncomingEvent = (value) => {
      console.log("**event data: ", value);
      if (value?.length > 0) setIncomingEvent(value);
    };

    socket.on("NEW DATA", onIncomingEvent);

    return () => {
      socket.off("NEW DATA", onIncomingEvent);
    };
  }, [incomingEvent]);

  // LOADING SCREEN
  if (load == true) {
    return <LoadingComponents />;
  }

  // GET DATA
  //   const [dataBar, setDataBar] = useState([]);
  //   const [cardData, setCardData] = useState([]);
  //   const [listData, setListData] = useState([]);
  //   // Loading
  //   const [load, setLoad] = useState(true);

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
          marginTop: 60,
          // backgroundImage: "url(palimanan-2.jpg)",
        }}
      >
        <Content style={{ height: "100%", padding: 10, margin: 2 }}>
          <Row className="row-content" style={{ width: "100%" }}>
            <Col span={24}>
              <MonitorCard newEvent={incomingEvent} />
            </Col>
          </Row>
        </Content>

        <Content style={{ padding: 10, margin: 10 }}>
          <Last5Table newEvent={incomingEvent} />
        </Content>

        <Content style={{ padding: 10, margin: 5 }}>
          <StatusTable />
        </Content>
      </Layout>
    </>
  );
}
