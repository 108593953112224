import React, { useContext, useEffect, useState } from "react";
import { Context as AppContext } from "../../../API/Context/AppContext";
import { showFailedFetch } from "../../Notification/Notification";
import { Col, Row, Space, Statistic } from "antd";
import { DashboardCard, Card } from "antd";
import { CarOutlined } from "@ant-design/icons";
import Title from "antd/es/skeleton/Title";

export default function InfographyColumn() {
  const [data, setData] = useState([]);
  const { getDailyChart } = useContext(AppContext);
  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // GET DATA
  const fetchData = () => {
    getDailyChart({
      mainData: "daily",
      onAwait: () => "On Await",
      onSuccess: (response) => {
        console.log("FETCH ==> ", response);
        setData(response?.data?.total);
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  console.log("Infography data -->", data);

  return (
    <>
      <Row
        // bordered={false}
        justify={"center"}
        className={"infography"}
        style={{ width: "100%", height: 150 }}
      >
        <Space direction="horizontal">
          <Card title="Small" size="default">
            <Space>
              {/* <CarOutlined style={{ color: "orange" }} /> */}
              <Statistic
                prefix={<CarOutlined style={{ color: "#8884d8" }} />}
                value={data?.small ? data.small : 0}
              />
              {/* </CarOutlined> */}
            </Space>
          </Card>
          <Card title="Medium" size="default">
            <Space>
              {/* <CarOutlined style={{ color: "blue" }}> */}
              <Statistic
                prefix={<CarOutlined style={{ color: "#82ca9d" }} />}
                value={data?.medium ? data.medium : 0}
              />
              {/* </CarOutlined> */}
            </Space>
          </Card>
          <Card title="Large" size="default">
            <Space>
              {/* <CarOutlined style={{ color: "blue" }}> */}
              <Statistic
                prefix={<CarOutlined style={{ color: "#5F9EA0" }} />}
                value={data?.large ? data.large : 0}
              />
              {/* </CarOutlined> */}
            </Space>
          </Card>
          <Card title="Long" size="default">
            <Space>
              {/* <CarOutlined style={{ color: "blue" }}> */}
              <Statistic
                prefix={<CarOutlined style={{ color: "#556B2F" }} />}
                value={data?.long ? data.long : 0}
              />
              {/* </CarOutlined> */}
            </Space>
          </Card>
          <Card title="Total" size="default">
            <Space>
              {/* <CarOutlined style={{ color: "blue" }}> */}
              <Statistic
                prefix={<CarOutlined style={{ color: "purple" }} />}
                value={data?.total ? data.total : 0}
              />
              {/* </CarOutlined> */}
            </Space>
          </Card>
        </Space>
      </Row>
    </>
  );
}
