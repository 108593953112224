// IMPORT REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// CONTEXT
import { Context as AppContext } from "../../../API/Context/AppContext";

// ANTD COMPONENTS
import {
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Image,
  Table,
  Statistic,
  Typography,
} from "antd";
import {} from "@ant-design/icons";

// NOTIFICATION
import { showFailedFetch } from "../../Notification/Notification";

// ICONS
// import { BsFillCpuFill, BiSolidMemoryCard, MdSdStorage } from "react-icons";
import { BsFillCpuFill } from "react-icons/bs";
import { BiSolidMemoryCard } from "react-icons/bi";
import { MdSdStorage } from "react-icons/md";

// CODE
export default function StatusTable() {
  // COLUMN FOR TABLE
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Integrity",
      dataIndex: "integrity",
      key: "integrity",
      render: (integrity, record) => {
        let convert = "";
        if (integrity == 1) {
          convert = "OK";
        } else {
          convert = "BAD";
        }
        return `${convert}`;
      },
    },
    {
      title: "Connection",
      dataIndex: "connection",
      key: "connection",
      render: (connection, record) => {
        let convert = "";
        if (connection == 1) {
          convert = "OK";
        } else {
          convert = "BAD";
        }
        return `${convert}`;
      },
    },
    {
      title: "Transmission",
      dataIndex: "transmission",
      key: "transmission",
      render: (transmission, record) => {
        let convert = "";
        if (transmission == 1) {
          convert = "OK";
        } else {
          convert = "BAD";
        }
        return `${convert}`;
      },
    },
  ];

  // GET DATE NOW
  const dates = new Date(Date.now());
  // CONTEXT
  const { getStatus } = useContext(AppContext);
  // DATA
  const [data, setData] = useState([]);
  const [dataStatus, setDataStatus] = useState([]);
  const [savedData, setSavedData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // DATE
  const [date, setDate] = useState(moment(dates).format("YYYY-MM-DD"));

  // GET DATA
  const fetchData = () => {
    getStatus({
      mainData: "status",
      onAwait: () => "on Await",
      onSuccess: (response) => {
        setData(response?.data[0]?.lidar);
        setDataStatus(response?.data[0]);
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // SHOW MODAL
  const showModal = (record) => {
    setIsModalOpen(true);
    setSavedData(record);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log(data);

  return (
    <>
      <Card>
        <Typography.Title style={{ textAlign: "center", paddingTop: 10 }}>
          Status
        </Typography.Title>
        <Row
          // bordered={false}
          justify={"center"}
          className={"status"}
          style={{ width: "100%", height: 150 }}
        >
          <Space direction="horizontal">
            <Card title="CPU" size="default">
              <Space>
                {/* <CarOutlined style={{ color: "orange" }} /> */}
                <Statistic
                  prefix={<BsFillCpuFill />}
                  value={
                    dataStatus?.cpu ? parseFloat(dataStatus.cpu).toFixed(2) : 0
                  }
                />
                {/* </CarOutlined> */}
              </Space>
            </Card>
            <Card title="Memory" size="default">
              <Space>
                {/* <CarOutlined style={{ color: "blue" }}> */}
                <Statistic
                  prefix={<BiSolidMemoryCard />}
                  value={
                    dataStatus?.memory
                      ? parseFloat(dataStatus.memory).toFixed(2)
                      : 0
                  }
                />
                {/* </CarOutlined> */}
              </Space>
            </Card>
            <Card title="Storage" size="default">
              <Space>
                {/* <CarOutlined style={{ color: "blue" }}> */}
                <Statistic
                  prefix={<MdSdStorage />}
                  value={
                    dataStatus?.storage
                      ? parseFloat(dataStatus.storage).toFixed(2)
                      : 0
                  }
                />
                {/* </CarOutlined> */}
              </Space>
            </Card>
          </Space>
        </Row>
      </Card>
      <Card style={{ padding: "10px 0px" }}>
        <Table
          columns={columns}
          dataSource={data}
          bordered={true}
          rowKey={(record) => (record?.id ? record.id : record.name)}
        />
      </Card>

      <Modal
        title="View Data"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={2000}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Card style={{ fontSize: 14 }}>
              <>
                <Divider orientation="left">Status</Divider>
                Name: {savedData ? savedData.name : "kosong"}
                <br />
                Integrity: {savedData ? savedData.integrity : "kosong"}
                <br />
                Connection: {savedData ? savedData.connection : "kosong"}
                <br />
                Transmission: {savedData ? savedData.transmission : "kosong"}
                <br />
              </>
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
