// IMPORT CONTEXT
import CreateContext from "./CreateContext";

// IMPORT API BASEURL
import apiLidar from "../Config/Api";

// REDUCERS
const lidarReducer = (state, action) => {
  if ((action.type = "getData")) {
    return {
      ...state,
      data: action.payload,
    };
  }
};

// CONTEXT GET DATA
// GET DATA
const getData =
  (dispatch) =>
  async ({ mainData, onAwait, onSuccess, onReject }) => {
    let query = "/" + mainData;
    if (onAwait) onAwait();

    apiLidar
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET DATA HISTORY
const getDataHistory =
  (dispatch) =>
  async ({ mainData, onAwait, onSuccess, onReject }) => {
    let query = "/" + mainData;
    if (onAwait) onAwait();

    apiLidar
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

const getDailyChart =
  (dispatch) =>
  async ({ mainData, onAwait, onSuccess, onReject }) => {
    let query = "/" + mainData;
    if (onAwait) onAwait();

    apiLidar
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

const getStatus =
  (dispatch) =>
  async ({ mainData, onAwait, onSuccess, onReject }) => {
    let query = "/" + mainData;
    if (onAwait) onAwait();

    apiLidar
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

const getLast5 =
  (dispatch) =>
  async ({ mainData, onAwait, onSuccess, onReject }) => {
    let query = "/" + mainData;
    if (onAwait) onAwait();

    apiLidar
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

  const getStatusMessage =
  (dispatch) =>
  async ({ mainData, onAwait, onSuccess, onReject }) => {
    let query = "/" + mainData;
    if (onAwait) onAwait();

    apiLidar
      .get(query)
      .then((response) => {
        dispatch({ type: "getStatusMessage", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

  const postStatusMessage =
  (dispatch) =>
  async ({ mainData, mainBody, onAwait, onSuccess, onReject }) => {
    let query = "/" + mainData;
    if (onAwait) onAwait();

    console.log("POST", mainBody)

    apiLidar
      .post(query, mainBody)
      .then((response) => {
        dispatch({ type: "postStatusMessage", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// const getDataHistoryLast3 =
//   (dispatch) =>
//   async ({ mainData, onAwait, onSuccess, onReject }) => {
//     let query = "/" + mainData;
//     if (onAwait) onAwait();

//     apiLidar
//       .get(query)
//       .then((response) => {
//         dispatch({ type: "getData", payload: response.data });
//         if (onSuccess) onSuccess(response);
//       })
//       .catch((error) => {
//         if (onReject) onReject(error);
//       });
//   };

// EXPORT CONTEXT AND PROVIDER
export const { Provider, Context } = CreateContext(lidarReducer, {
  getStatusMessage,
  postStatusMessage,
  getData,
  getDataHistory,
  getDailyChart,
  getStatus,
  getLast5,
});
