// Import React's Components
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// Import Ant Design's Components
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Col, Grid, Image, Row, Typography } from "antd";

const { Title, Text } = Typography;

// Breakpoint
const { useBreakpoint } = Grid;

export default function HeaderComponents() {
  // SCREEN WIDTH
  const { xxs, xs, sm, md, lg, xl } = useBreakpoint();
  let width = window.screen.width;

  // GET TOKEN
  const auth_token = sessionStorage.getItem("auth-token");
  const admin = sessionStorage.getItem("admin") == "true" ? true : false;

  return (
    <>
      {/* Avatar */}
      <Row
        justify={"space-between"}
        style={{
          paddingLeft: 10,
          // paddingRight: 50,
          paddingTop: 15,
        }}
      >
        <Col xs={0} sm={12} md={10} lg={8} xl={16} xxl={10}>
          <Row>
            <Link to={"/"}>
              <Typography
                style={{
                  display: "flex",
                  color: "white",
                  verticalAlign: "center",
                  alignItems: "center",
                  fontWeight: "bolder",
                  paddingLeft: 10,
                  cursor: "pointer",
                  fontSize: 25,
                }}
              >
                LIDAR APP MONITORING
              </Typography>
            </Link>
          </Row>
        </Col>

        {/* Log in / Log out */}
        <Col xs={12} sm={12} md={8} lg={10} xl={8} xxl={5}>
          {auth_token ? (
            <Link
              to={"/"}
              onClick={() => {
                sessionStorage.clear();
                const time = setTimeout(() => {
                  window.location.reload(false);
                }, 100);

                return () => clearTimeout(time);
              }}
            >
              {admin == true ? (
                <Text
                  type="danger"
                  style={
                    //   xl || lg || md || sm
                    //     ?
                    {
                      // color: "white",
                      // display: "flex",
                      verticalAlign: "center",
                      alignItems: "center",
                      fontWeight: "bolder",
                      fontSize: "25px",
                      // padding: 25,
                      // paddingRight: 200,
                    }
                    // : {
                    //     color: "white",
                    //     verticalAlign: "center",
                    //     alignItems: "center",
                    //     fontSize: "15px",
                    //   }
                  }
                >
                  <Badge>
                    <Avatar
                      shape="circle"
                      style={{
                        color: "#001529",
                        fontWeight: "bolder",
                        fontSize: "20px",
                        marginRight: "15px",
                        backgroundColor: "#FFFFFF",
                        verticalAlign: "center",
                        marginBottom: "5px",
                      }}
                    >
                      A
                    </Avatar>
                  </Badge>
                  Log out
                  <DoubleRightOutlined
                    style={{ margin: 5, paddingBottom: 10 }}
                  />
                </Text>
              ) : (
                <Text
                  type="danger"
                  style={
                    //   xl || lg || md || sm
                    //     ?
                    {
                      // color: "white",
                      // display: "flex",
                      verticalAlign: "center",
                      alignItems: "center",
                      fontWeight: "bolder",
                      fontSize: "25px",
                      // padding: 25,
                      // paddingRight: 200,
                    }
                    // : {
                    //     color: "white",
                    //     verticalAlign: "center",
                    //     alignItems: "center",
                    //     fontSize: "15px",
                    //   }
                  }
                >
                  <Badge>
                    <Avatar
                      shape="circle"
                      style={{
                        color: "#52c41a",
                        fontWeight: "bolder",
                        fontSize: "20px",
                        marginRight: "15px",
                        backgroundColor: "#FFFFFF",
                        verticalAlign: "center",
                        marginBottom: "5px",
                      }}
                    >
                      U
                    </Avatar>
                  </Badge>
                  Log out
                  <DoubleRightOutlined
                    style={{ margin: 5, paddingBottom: 10 }}
                  />
                </Text>
              )}
            </Link>
          ) : (
            <Link to={"/login"}>
              <Text
                type="danger"
                style={{
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "20px",
                }}
              >
                Log In
                <DoubleLeftOutlined
                  twoToneColor={"green"}
                  style={{ margin: 5 }}
                />
              </Text>
            </Link>
          )}
        </Col>
      </Row>
    </>
  );
}
