import { io } from "socket.io-client";

const URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_WEBSOCKET_BASEURL
    : process.env.REACT_APP_WEBSOCKET_BASEURL_DEVELOPMENT;

export const socket = io(URL, {
  autoConnect: false,
  timeout: 5000
});

