// Import React's Components
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import * as XLSX from "xlsx";

// CONTEXT
import { Context as AppContext } from "../../../API/Context/AppContext";

// Import Ant Design Components
import { Button, DatePicker, Input } from "antd";
import { FileExcelFilled } from "@ant-design/icons";

// Notification
import { showFailedFetch } from "../../Notification/Notification";

const { RangePicker } = DatePicker;

// CODE
export default function ExcelComponents({ dataExc, onSet }) {
  // GET DATE NOW
  const dates = new Date(Date.now());
  // CONTEXT
  const { state, getHistoryReport } = useContext(AppContext);
  // STATE MANAGEMENT
  // Excel Data
  const [data, setData] = useState([]);
  // Start Date
  const [startDate, setStartDate] = useState(
    moment(dates).subtract(1, "days").format("YYYY-MM-DD")
  );
  // End Date
  const [endDate, setEndDate] = useState(moment(dates).format("YYYY-MM-DD"));

  // Set Pick for Date
  //   const getStartDate = (newDate) => {
  //     setStartDate(moment(newDate[0]).format("YYYY-MM-DD"));
  //     setEndDate(moment(newDate[1]).format("YYYY-MM-DD"));
  //     console.log("START DATE => ", startDate);
  //     console.log("END DATE => ", endDate);
  //   };

  //   const onChange = (value, dateValue) => {
  //     // setStartDate(value);
  //     console.log("1st Value => ", value);
  //     console.log("2nd Value => ", dateValue);
  //   };

  // GET DATA
  //   const fetchData = () => {
  //     getHistoryReport({
  //       history: "history",
  //       startDate: startDate,
  //       endDate: endDate,
  //       onAwait: () => "On Await",
  //       onSuccess: (response) => {
  //         console.log("FETCH ==> ", response);
  //         setData(response.data);
  //       },
  //       onReject: (error) => {
  //         console.log(error);
  //         showFailedFetch(error);
  //       },
  //     });
  //   };

  // USE EFFECT
  useEffect(() => {
    // fetchData();

    if (dataExc) {
      setData(dataExc);
    } else {
      setData([]);
    }
  }, [dataExc]);

  // EXPORT TO EXCEL
  // Download File as Excel
  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Report File.xlsx");
  };

  console.log("START DATE >>> ", startDate);
  console.log("END DATE >>> ", endDate);

  return (
    <>
      <RangePicker
        size="large"
        style={{ marginRight: 50, width: "75%" }}
        onChange={(value) => {
          setStartDate(moment(value[0]).format("YYYY-MM-DD"));
          setEndDate(moment(value[1]).format("YYYY-MM-DD"));
          //   fetchData();
          console.log(
            "On Change Value =>>> ",
            value
            // moment(value).format("YYYY-MM-DD")
          );

          //   getStartDate(value);
          if (onSet) onSet(value);
        }}
        // onOk={fetchData}
      />

      <Button
        type="primary"
        icon={<FileExcelFilled />}
        onClick={() => downloadExcel(data || [])}
      >
        Export
      </Button>
    </>
  );
}
